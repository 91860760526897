import breakpoints from 'constants/breakpoints';

import styled from 'styled-components';

export const Container = styled.div`
    // -70px on mobile because Chamber has translateY -220px
    margin: -70px 0 150px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.06);
    gap: 70px;
    padding: 60px 16px;

    ${breakpoints.aboveTablet} {
        flex-direction: row;
        width: calc(100% - 30px);
        border-radius: 24px 0px 0px 24px;
        margin: 100px 0 162px 0;
        margin-left: auto;
        padding: 60px;
        align-items: center;
    }
`;

export const Column = styled.div<{gap?: number}>`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: ${({gap}) => (gap ? `${gap}px` : 'initial')};
`;

export const ImageContainer = styled.div`
    width: 100%;
    height: 100%;
    max-width: 700px;
    margin: 40px auto 0 auto;
    padding: 0 30px;

    ${breakpoints.tablet} {
        max-width: 500px;
    }

    ${breakpoints.phoneS} {
        max-width: unset;
        width: calc(100% + 80px);
        margin-left: -40px;
    }

    ${breakpoints.abovePhone} {
        padding: 0;
    }
`;
