import breakpoints from 'constants/breakpoints';

import styled from 'styled-components';

export const Container = styled.div<{color: string}>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 8px;
    padding: 10px 15px 10px 25px;
    position: relative;
    overflow: hidden;
    gap: 15px;

    ${breakpoints.abovePhoneS} {
        flex-direction: row;
        justify-content: space-between;
    }

    &::before {
        content: '';
        height: 100%;
        width: 4px;
        position: absolute;
        left: 0;
        top: 0;
        background-color: ${({color}) => color};
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;

    ${breakpoints.abovePhoneS} {
        &:nth-child(2) {
            text-align: right;
        }
    }
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Text = styled.p<{
    size: number;
    color?: string;
    marginRight?: number;
    bold?: boolean;
    opacity?: number;
}>`
    padding: 0;
    margin: 0;
    letter-spacing: 0.06em;
    font-size: ${({size}) => `${size}px`};
    color: ${({color}) => color || 'white'};
    margin-right: ${({marginRight}) => (marginRight ? `${marginRight}px` : '0px')};
    font-weight: ${({bold}) => (bold ? `bold` : 'lighter')};
    opacity: ${({opacity}) => opacity || 1};
`;
