import {PageSectionsQueryContentComponentSectionTokenomicsBlocks} from '~interfaces/graphql/PageSectionsQuery';

import {Column, Container, Row, Text} from './styles';

const TokenomicsBlock = ({
    color,
    coloredText,
    whiteText,
    description,
    tokenName,
    tokenValue,
}: PageSectionsQueryContentComponentSectionTokenomicsBlocks) => {
    return (
        <Container color={color}>
            <Column>
                <Row>
                    <Text size={14} color={color} marginRight={10} bold>
                        {coloredText}
                    </Text>
                    <Text size={14} bold>
                        {whiteText}
                    </Text>
                </Row>
                <Text size={12} opacity={0.6}>
                    {description}
                </Text>
            </Column>
            <Column>
                <Text size={12} opacity={0.6} bold>
                    {tokenName}
                </Text>
                <Text size={12} opacity={0.6}>
                    {tokenValue}
                </Text>
            </Column>
        </Container>
    );
};

export default TokenomicsBlock;
