import {useMemo} from 'react';
import Image from 'next/image';

import {getImageData} from '~utils/imageData';
import {Anchor} from '~atoms/AbsoluteAnchor';
import TokenomicsBlock from '~components/molecules/TokenomicsBlock';
import Text from '~components/atoms/Text';
import {PageQueryPageDataAttributesContentComponentSectionTokenomics} from '~interfaces/graphql/PageQuery';

import {Column, Container, ImageContainer} from './styles';

const Tokenomics = ({
    sectionId,
    sectionName,
    sectionTitle,
    description,
    image,
    blocks,
}: PageQueryPageDataAttributesContentComponentSectionTokenomics) => {
    const {src, alt} = useMemo(() => getImageData(image), []);
    return (
        <Container>
            <Anchor id={sectionId} />
            <Column>
                <Text type="sectionName" color="pink">
                    {sectionName}
                </Text>
                <Text type="sectionTitle">{sectionTitle}</Text>
                <Text type="content">{description}</Text>
                <ImageContainer>
                    <Image
                        src={src}
                        alt={alt}
                        width={564}
                        height={503}
                        layout="responsive"
                    />
                </ImageContainer>
            </Column>
            <Column gap={20}>
                {blocks.map((item, index) => (
                    <TokenomicsBlock key={index} {...item} />
                ))}
            </Column>
        </Container>
    );
};

export default Tokenomics;
